"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownHandler = void 0;
var Dropdown = __importStar(require("../constants/dropdownConstants"));
var Common = __importStar(require("../constants"));
var DropdownHandler = /** @class */ (function () {
    function DropdownHandler() {
    }
    /**
     * @method handler ドロップダウンのイベントが発生した時の処理
     * @param {Event} e 押下イベント
     */
    DropdownHandler.prototype.handler = function (e) {
        var _a;
        var trigger = e.currentTarget;
        if (!trigger || !(trigger instanceof Element))
            return;
        var triggerControls = trigger.getAttribute("aria-controls");
        var otherTriggers = document.querySelectorAll("[aria-controls=\"".concat(triggerControls, "\"]"));
        var target = triggerControls
            ? document.getElementById(triggerControls)
            : (_a = trigger.closest("div")) === null || _a === void 0 ? void 0 : _a.getElementsByClassName(Dropdown.CLASS_NAMES.TARGET).item(0);
        if (!target)
            return;
        if (this.isExpanded(trigger) || !this.isHidden(target)) {
            this.close(trigger, target, otherTriggers);
        }
        else {
            this.open(trigger, target, otherTriggers);
        }
        this.toggleTriggerLabel(trigger);
    };
    /**
     * @method isExpanded aria-expand="true"か
     * @param {Element} element
     * @returns {Boolean} "false" || null で false
     */
    DropdownHandler.prototype.isExpanded = function (element) {
        return element.getAttribute("aria-expanded") === "true";
    };
    /**
     * @method isExpanded aria-expand="true"か
     * @param {Element} element
     * @returns {Boolean} "false" || null で false
     */
    DropdownHandler.prototype.isHidden = function (element) {
        if (element.getAttribute("aria-hidden") === "true" || !element.classList.contains(Common.CLASS_NAMES.ACTIVE))
            return true;
        return false;
    };
    /**
     * @method open ドロップダウンを開く
     *
     */
    DropdownHandler.prototype.open = function (trigger, target, otherTriggers) {
        trigger.classList.add(Common.CLASS_NAMES.ACTIVE);
        trigger.setAttribute("aria-expanded", "true");
        target.classList.add(Common.CLASS_NAMES.ACTIVE);
        if (target.hasAttribute("aria-hidden")) {
            target.setAttribute("aria-hidden", "false");
        }
        if (target.childElementCount > 0 &&
            Array.from(target.children).some(function (child) { return child.hasAttribute("aria-hidden"); })) {
            var targetChildren = Array.from(target.children).filter(function (child) { return child.hasAttribute("aria-hidden") && !child.hasAttribute("aria-expanded"); });
            targetChildren.forEach(function (child) {
                child.setAttribute("aria-hidden", "false");
            });
        }
        if (otherTriggers) {
            otherTriggers.forEach(function (trigger) {
                trigger.setAttribute("aria-expanded", "true");
            });
        }
    };
    DropdownHandler.prototype.close = function (trigger, target, otherTriggers) {
        trigger.classList.remove(Common.CLASS_NAMES.ACTIVE);
        trigger.setAttribute("aria-expanded", "false");
        target.classList.remove(Common.CLASS_NAMES.ACTIVE);
        if (target.hasAttribute("aria-hidden")) {
            target.setAttribute("aria-hidden", "true");
        }
        if (target.childElementCount > 0 &&
            Array.from(target.children).some(function (child) { return child.hasAttribute("aria-hidden"); })) {
            var targetChildren = Array.from(target.children).filter(function (child) { return child.hasAttribute("aria-hidden") && !child.hasAttribute("aria-expanded"); });
            targetChildren.forEach(function (child) {
                child.setAttribute("aria-hidden", "true");
            });
        }
        if (otherTriggers) {
            otherTriggers.forEach(function (trigger) {
                trigger.ariaExpanded = "false";
            });
        }
    };
    DropdownHandler.prototype.toggleTriggerLabel = function (trigger) {
        var newLabel = trigger.getAttribute("data-label");
        var currentLabel = trigger.textContent;
        if (!newLabel || !currentLabel)
            return;
        trigger.setAttribute("data-label", currentLabel);
        trigger.textContent = currentLabel.replace(currentLabel, newLabel);
    };
    return DropdownHandler;
}());
exports.DropdownHandler = DropdownHandler;
