"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Dropdown = __importStar(require("./constants/dropdownConstants"));
var Drawer = __importStar(require("./constants/drawerConstants"));
var DropdownListener_1 = require("./modules/DropdownListener");
/**
 * @constant { HTMLCollectionOf<Element> } dropDownList ドロップダウンのトリガー
 */
var dropDownList = document.getElementsByClassName(Dropdown.CLASS_NAMES.TRIGGER);
var drawerList = document.getElementsByClassName(Drawer.CLASS_NAMES.TRIGGER);
window.addEventListener("load", function () {
    if (dropDownList) {
        for (var i = 0; i < dropDownList.length; i++) {
            var trigger = dropDownList[i];
            if (!(trigger instanceof HTMLElement))
                break;
            var listener = new DropdownListener_1.DropdownListener(trigger);
            listener.listen();
        }
    }
    if (drawerList) {
        for (var i = 0; i < drawerList.length; i++) {
            var trigger = drawerList[i];
        }
    }
});
